import React from 'react'
import Link from 'gatsby-link'

import startPageThumb from '../img/startPage/startPage_thumb.jpeg'
import startPage from '../img/startPage/startPage.jpeg'

import certificationPlumber from '../img/certificationPlumber.png'
import certificationDrainlayer from '../img/certificationDrainlayer.png'
import certificationGas from '../img/certificationGas.png'
import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <div>
      <div id="missionStatement" className="box">
        <h2 className="stripe">Mission Statement</h2>

        <p>
          Ace High plumbing is a family owned and operated business with over 40years experience in both New Zealand and
          Australia. The business has been in Canterbury now for almost 10 years and we have been there for our clients
          through both earthquakes. Safe to say all our work is still standing strong today.
        </p>
        <p>
          Currently we are working everywhere in Canterbury from as far north as Cheviot, to as south as Rakaia and all
          places in between. We do a huge variety of work from Historic homes, new bathrooms, renovations, pre slab
          drainage and even one off gas prototype machines for organic farmers.
        </p>
        <p>
          We are flexible enough to have two main bases one in the heart of Christchurch and the other in the Selwyn
          district. This keeps our costs down for our customers and means we can get to your job sooner.
        </p>
      </div>

      <div id="services">
        <div id="servicesPlumbing" className="box">

          <Link to='/services/plumbing'>
            <h4 className="stripe">
              <img src={certificationPlumber} width="40" height="40"/> Plumbing
            </h4>
          </Link>
        </div>

        <div id="servicesDrainLaying" className="box">
          <Link to="/services/drainlaying">
            <h4 className="stripe">
              <img src={certificationDrainlayer} width="40" height="40"/> Drain Laying
            </h4>
          </Link>
        </div>

        <div id="servicesGasFitting" className="box">
          <Link to="/services/gasfitting">
            <h4 className="stripe">
              <img src={certificationGas} width="40" height="40"/> Gas Fitting
            </h4>
          </Link>
        </div>
      </div>

      <br className="clear"/>

      <div id="furtherInformation" className="box">
        <h3 className="stripe">Fully Certified</h3>
        <a href={startPage} className="fancybox" rel="fancybox">
          <img src={startPageThumb} style={{
            float: 'left',
            width: '340px',
            marginRight: '20px',
            marginBottom: '20px',
          }}/>
        </a>

        <p>
          We are fully certified by the New Zealand P.G.D.B in all three areas of our industry - Plumbing Drainlaying
          and
          Gas Fitting. This means we are trained to the highest possible standard our industry offers, giving you a job
          done right first time every time. As we are certified in all three areas, it also insures dealing with less
          tradesmen on the job making your new build or renovation run smoother and more efficiently.
        </p>
        <p>
          Being certified means that we authorised to complete all types of jobs whether its a simple tap replacement or
          a complete renovation. It's important as consumers to be aware of the potential hazards of employing tradesmen
          whom aren't authorised to complete work, as dodgy work could mean a redo, or put your family's health at risk.
          For more information about the certification regulations and process refer to the <a
          href="http://www.pgdb.co.nz/">plumbers, gasfitters and drainlayers board</a> website.
        </p>

        <br className="clear"/>
      </div>
    </div>
  </Layout>
)

export default IndexPage
